<div class="fied">
  <label class="label">Status</label>
  <div class="control has-icons-left">
    <div class="select">
      <div class="control">
        <select [(ngModel)]="selectedStatus" (ngModelChange)="change($event)" name="sel2">
          <option [ngValue]="status.value" *ngFor="let status of statuses">{{status.name}}</option>
        </select>
      </div>
      <div class="icon is-small is-left">
        <i class="las la-filter"></i>
      </div>
    </div>
  </div>
</div>
