import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BudgetYear } from './models/budgetYear.interface';
import { AuthService } from './services/auth.service';
import { SetBudgetYearBudgetPost } from './store/actions/budget-post.action';
import { GetBudgetYears } from './store/actions/budget-year.action';
import { SetBudgetYearOrder } from './store/actions/order.action';
import { GetRoles } from './store/actions/role.action';
import { BudgetYearState } from './store/states/budget-year.state';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  currentUser: any;
  activeRole: number;

  @Select(BudgetYearState.budgetYears)
  budgetYears$: Observable<BudgetYear[]>;

  constructor(
    private store: Store,
    private authService: AuthService,
    private router: Router
  ) {
    this.authService.currentUser.subscribe((user) => {
      this.currentUser = user;
      if (user) {
        this.store.dispatch(new GetBudgetYears());
        this.store.dispatch(new GetRoles());

        this.budgetYears$.subscribe((budgetYears: BudgetYear[]) => {
          if (budgetYears.length > 0) {
            this.activeRole;

            let years = budgetYears.filter((budgetYear: BudgetYear) => {
              let today = new Date();
              let startDate = new Date(Date.parse(budgetYear.start_date));
              let endDate = new Date(Date.parse(budgetYear.end_date));

              return (
                today.getTime() >= startDate.getTime() &&
                today.getTime() <= endDate.getTime()
              );
            });

            if (years.length > 0) {
              this.activeRole = years[0].id;
            } else {
              this.activeRole = 0;
            }
          }
        });
      }
    });
  }

  ngOnInit() {}

  updateActiveRole(value: number) {
    this.store.dispatch(new SetBudgetYearOrder(+value));
    this.store.dispatch(new SetBudgetYearBudgetPost(+value));
  }

  logout() {
    this.router.navigate(['/login']);
    this.authService.logout();
  }
}
